import Header from "./Header";
import toast, { Toaster } from "react-hot-toast";
import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { setAuthorization } from "../core/service/axios";

function Landing() {
  const initialFormValue = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [tfaValidate, settfaValidate] = useState(false);
  const [validationnErr, setvalidationnErr] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);

  const { tfa } = formValue;
  const { state } = useLocation();

  const validate = async (values) => {
    const errors = {};
    if (!values.tfa) {
      errors.tfa = "2FA is Required";
      settfaValidate(true);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const formSubmit = async () => {
    validate(formValue);
    if (formValue.tfa !== "") {
      var data = {
        apiUrl: apiService.verify_otp,
        payload: {
          tfa_code: tfa,
          socketToken: localStorage.getItem("socket_token"),
        },
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      console.log("tfa login===", resp);
      if (resp.status) {
        toast.success(resp.Message);
        await setAuthorization(resp.token);
        localStorage.setItem("user_token", resp.token);
        localStorage.setItem("tfa_status", 1);
        localStorage.setItem("socket_token", resp.socketToken);
        navigate("/dashboard");
      } else {
        toast.error(resp.Message);
      }
    }
  };

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
      <Header />
      <div className="admin_login_card">
        <div className="container">
          <div className="row mark_pagi">
            <div className="col-lg-6">
              <div className="admin_login_main">
                <div className="hero_subhead">
                  <span className="hero_head text-align-center">
                    Verify 2FA
                  </span>
                  <div className="ycho_inner">
                    <div className="hotpic_bot_hd">
                      <span className="hero_sub_inner">Email</span>
                      <input
                        className="admin_login_imput"
                        type="number"
                        placeholder="Enter Your 2FA Code "
                        min={100000}
                        name="tfa"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        value={tfa}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length <= 6) {
                            setFormValue({ tfa: value });
                          }
                        }}
                      />

                      {tfaValidate == true ? (
                        <p className="text-danger"> {validationnErr.tfa} </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center w_100">
                    <div className="lan_had_con">
                      {!buttonLoader ? (
                        <span className="con_lan_con" onClick={formSubmit}>
                          Submit
                        </span>
                      ) : (
                        <span className="con_lan_con">Loading ...</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
