import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import Moment from "moment";
import ReactPaginate from "react-paginate";
import { postMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import apiService from "../core/service/detail";

function Dashboard() {
  const [Usersdata, setUsersdata, Usersdataref] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getUserDetails(currentPage);
  }, [currentPage]);

  const getUserDetails = async (page = 1) => {
    var datas = {
      apiUrl: apiService.get_all_user_deposit,
      payload: { page, limit: 10 },
    };
    var response = await postMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      setUsersdata(response.data);
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
    } else {
      setUsersdata([]);
    }
  };

  const handleFilterChange = (e) => {
    setFilterKeyword(e.target.value);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    getUserDetails(selectedPage);
  };

  const filteredUsers = Usersdata.filter((user) =>
    user.username.toLowerCase().includes(filterKeyword.toLowerCase())
  );

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="px-2 my-4 transaction_padding_top tops">
                <div className="headerss">
                  <span className="dash-head">Crypto Deposit History </span>
                  <div>
                    <input
                      className="filters"
                      placeholder="Filter"
                      value={filterKeyword}
                      onChange={handleFilterChange}
                    />
                  </div>
                </div>
                <div class="table-responsive my-5  trans-table ">
                  <table className="w_100">
                    <thead className="trans-head">
                      <tr>
                        <th>S.No</th>
                        <th>Username</th>
                        <th>UserID</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>Transaction Id </th>
                        {/* <th>Currency</th> */}
                        <th>Amount</th>
                        {/* <th>Status</th> */}
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.length > 0 ? (
                        filteredUsers.map((item, i) => (
                          <tr key={item._id}>
                            <td>
                              <span className="plus_14_ff">{i + 1}</span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                {item.username}
                              </span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                {item.userId}
                              </span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                {item.email}
                              </span>
                            </td>
                            <td>
                              <span className="plus_14_ff">
                                {item.mobileNumber}
                              </span>
                            </td>
                            <td>
                              <span className="plus_14_ff">{item.txnid}</span>
                            </td>
                            {/* <td>
                              <span className="plus_14_ff">
                                {item.currencySymbol}
                              </span>
                            </td> */}
                            <td>
                              <label className="plus_14_ff">
                                {item.amount}
                              </label>
                            </td>
                            {/* <td>
                              <label className="plus_14_ff">
                                {item.status}
                              </label>
                            </td> */}
                            <td>
                              <span className="plus_14_ff">
                                {Moment(item.date).format("lll")}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8}>
                            <div className="empty_data">
                              <div className="plus_14_ff">No Records Found</div>
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr className="text-center">
                        <td colSpan="7">
                          <div className="paginationcss">
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              pageCount={totalPages}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={
                                "pagination pagination-md justify-content-center "
                              }
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
