import React, { useEffect } from "react";
import useState from "react-usestateref";

import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import ReactPaginate from "react-paginate";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import Moment from "moment";
import { toast } from "react-toastify";

function Dashboard() {
  useEffect(() => {
    getUserDetails();
    getDepositDetails();
    getwithdrawDetails();
  }, []);

  const [logindata, setlogindata, logindataref] = useState("");
  const [userdata, setuserdata, userdataref] = useState("");
  const [depositdata, setdepositdata, depositdataref] = useState("");
  const [withdrawdata, setwithdrawdata, withdrawdataref] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getDashboardDetails(currentPage);
  }, [currentPage]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const getDashboardDetails = async (page = 1) => {
    var datas = {
      apiUrl: apiService.adminlogg,
      payload: { page, limit: 5 },
    };
    var response = await postMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      setlogindata(response.Message);
      logindataref.current = response.Message;
      setTotalPages(response.totalPages);
    } else {
      setlogindata([]);
    }
  };

  const getUserDetails = async () => {
    var datas = {
      apiUrl: apiService.userscount,
    };
    var response = await getMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      // setreferralUser(responce.data.length);
      setuserdata(response.Message);
    } else {
      setuserdata("");
    }
  };

  const getDepositDetails = async () => {
    var datas = {
      apiUrl: apiService.depositcount,
    };
    var response = await getMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      // setreferralUser(responce.data.length);
      setdepositdata(response.Message);
    } else {
      setdepositdata("");
    }
  };

  const getwithdrawDetails = async () => {
    var datas = {
      apiUrl: apiService.withdrawcount,
    };
    var response = await getMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      // setreferralUser(responce.data.length);
      setwithdrawdata(response.Message);
    } else {
      setwithdrawdata("");
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="ycho_inner my-5">
                <span className="dash-head">Dashboard</span>
                {/* <span className="pls_16_dd"> Welcome To WXDL</span> */}
              </div>
              <div className="dash_frst">
                <div className="row dash_frst_inmain">
                  <div className="col-lg-4">
                    <div className="dash_frst_scnd">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Total User </span>
                        <span className="ychose_in_hed">{userdataref.current}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dash_frst_thrd">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Deposit Count</span>
                        <span className="ychose_in_hed">{depositdataref.current}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dash_frst_frth">
                      <div className="hero_subhead">
                        <span className="pls_16_dd"> Withdraw Count </span>
                        <span className="ychose_in_hed"> {withdrawdataref.current} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-2 my-4 transaction_padding_top">
      <span className="dash-head">Transaction</span>
      <div className="table-responsive my-4 trans-table">
        <table className="w_100">
          <thead className="trans-head">
            <tr>
              <th>SI.NO</th>
              <th>Date & Time</th>
              <th>Browser</th>
              <th>Ip-Address</th>
            </tr>
          </thead>
          <tbody>
            {logindataref.current && logindataref.current.length > 0 ? (
              logindataref.current.map((item, i) => (
                <tr key={i}>
                  <td>
                    <span className="plus_14_ff">{i + 1}</span>
                  </td>
                  <td>
                    <span className="plus_14_ff">
                      {Moment(item.createdDate).format("lll")}
                    </span>
                  </td>
                  <td>
                    <span className="plus_14_ff">{item.browser}</span>
                  </td>
                  <td>
                    <span className="plus_14_ff">{item.ipAddress}</span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className="empty_data">
                    <div className="plus_14_ff">No Records Found</div>
                  </div>
                </td>
              </tr>
            )}

            <tr className="text-center">
              <td colSpan="4">
                <div className="paginationcss">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"**"}
                    pageCount={totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination pagination-md justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
