import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import { getMethod, postMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import apiService from "../core/service/detail";

function StakingHistory() {
    const [Usersdata, setUsersdata, Usersdataref] = useState([]);
    const [filterKeyword, setFilterKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const pageSize = 10; // Define how many items per page
    const [usersList,setusersList] = useState([]);

    useEffect(() => {
        getUserDetails(0);
    }, []);

    const getUserDetails = async (page) => {
        console.log("call stake")
        var datas = {
            apiUrl: apiService.stakeHistory,
            payload: {
                currentPage: page + 1, // ReactPaginate uses 0-based index, so we add 1
                pageSize: pageSize,
            },
        };
        var response = await postMethod(datas);
        if (response.status) {
            setusersList(response.value);
            setPageCount(response.pages);
        } else {
            setusersList([]);
        }
    };

    const handleFilterChange = (e) => {
        setFilterKeyword(e.target.value);
        let filteredUsers = Usersdata.filter((user) =>
            user.userId.toLowerCase().includes(filterKeyword.toLowerCase())
        );
        setusersList(filteredUsers)
    };

    //const filteredUsers = Usersdata;

    // const filteredUsers = Usersdata.filter((user) =>
    //     user.userId.toLowerCase().includes(filterKeyword.toLowerCase())
    // );

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setCurrentPage(selectedPage);
        getUserDetails(selectedPage);

    };

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 px-0">
                        <Sidebar />
                    </div>
                    <div className="col-lg-10 px-0">
                        <div className="pos_sticky">
                            <Sidebar_2 />
                        </div>
                        <div className="px-4 transaction_padding_top">
                            <div className="px-2 my-4 transaction_padding_top tops">
                                {/* <div className="headerss">
                                    <span className="dash-head">Staking Management</span>
                                    <div>
                                        <input className="filters" placeholder="Filter userId"
                                            value={filterKeyword}
                                            onChange={handleFilterChange} />
                                    </div>
                                </div> */}
                                <div className="table-responsive my-5 trans-table">
                                    <table className="w_100">
                                        <thead className="trans-head">
                                            <tr>
                                                <th>S.No</th>
                                                <th>UserId</th>
                                                <th>Email</th>
                                                <th>Mobile Number</th>
                                                <th>Package Amount</th>
                                                <th>Status</th>
                                                <th>Sponsor ID</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersList.length > 0 ? (
                                                usersList.map((item, i) => (
                                                    <tr key={item._id}>
                                                        <td>
                                                            <span className="plus_14_ff">{i + 1}</span>
                                                        </td>
                                                        <td>
                                                            <span className="plus_14_ff">
                                                                {item.userId}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span className="plus_14_ff">{item.email}</span>
                                                        </td>
                                                        <td>
                                                            <span className="plus_14_ff">
                                                                {item.mobileNumber}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <label className="plus_14_ff">
                                                                {item.amount}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="plus_14_ff">
                                                                {item.status}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="plus_14_ff">
                                                                {item.sponsor}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <span className="plus_14_ff">
                                                                {Moment(item.date).format("lll")}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={8}>
                                                        <div className="empty_data">
                                                            <div className="plus_14_ff">No Records Found</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="paginationcss">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            breakLabel={"..."}
                                            pageCount={pageCount}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={handlePageClick}
                                            containerClassName={"pagination pagination-md justify-content-center"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakClassName={"page-item"}
                                            breakLinkClassName={"page-link"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StakingHistory;
