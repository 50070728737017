import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import ReactPaginate from "react-paginate";

import { toast } from "react-toastify";
import Moment from "moment";
import { getMethod, postMethod } from "../core/service/common.api";
import useState from "react-usestateref";
import apiService from "../core/service/detail";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


function Dashboard() {
  const [add, setadd] = useState(false);
  const [editorData, setEditorData] = useState('');
  const [Usersdata, setUsersdata, Usersdataref] = useState([]);
  const [cmsdata, setcmsdata, cmsdataref] = useState([]);
  const [filterKeyword, setFilterKeyword] = useState("");
  const [errors, setErrors] = useState({});


  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    var datas = {
      apiUrl: apiService.cms_list,
    };
    var response = await getMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");
    if (response.status) {
      setUsersdata(response.data);
    } else {
      setUsersdata([]);
    }
  };
  const handleFilterChange = (e) => {
    setFilterKeyword(e.target.value);
  };

  const filteredUsers = Usersdata.filter((user) =>
    user.heading.toLowerCase().includes(filterKeyword.toLowerCase())
  );

  const getEditDetails = async (data) => {

    var obj = {
      _id: data,
    }
    var datas = {
      apiUrl: apiService.cms_get,
      payload: obj,
    };
    var response = await postMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");

    if (response.status) {
      setcmsdata(response.data);
      setadd(true);
    } else {
      setcmsdata({});
    }
  };

  const handleStatusChange = async (userId, currentStatus) => {

    const newStatus = currentStatus === 0 ? 1 : 0;
    console.log(newStatus, "ijuiewhuiewhiwefhwefi")

    let formData = { ...cmsdataref.current, ...{ ["status"]: newStatus } };
    setcmsdata(formData);

  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...cmsdataref.current, ...{ [name]: sanitizedValue } };
    setcmsdata(formData);
    // validate(formData);
    console.log(cmsdataref.current, "handlechange");
  };


  const validate = () => {
    let formErrors = {};
    if (!cmsdataref.current.heading) formErrors.heading = "Heading is required";
    if (!cmsdataref.current.title) formErrors.title = "Title is required";
    if (!cmsdataref.current.link) formErrors.link = "Link is required";
    if (!cmsdataref.current.meta_keyword) formErrors.meta_keyword = "Meta-Keyword is required";
    if (!cmsdataref.current.meta_description) formErrors.meta_description = "Meta-Description is required";
    if (!editorData) formErrors.content_description = "Content-Description is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  const formSubmit = async () => {
    if (!validate()) {
      console.log("Required fields are missing");
      return;
    }

    
    // validate(cmsdataref.current);
    // if (emailValidateref.current === false) {

    cmsdataref.current.content_description = editorData == "" ? cmsdataref.current.content_description : editorData  ;

    console.log(cmsdataref.current);

    var data = {
      apiUrl: apiService.cms_update,
      payload: cmsdataref.current,
    };
    var resp = await postMethod(data);

    if (resp.status) {
      toast.success(resp.Message);
      setadd(false);
      getUserDetails();
    } else {
      toast.error(resp.Message);
      setadd(false);
      getUserDetails();
    }
    // } else {
    //   console.log("Required all fields");
    // }
  };




  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="px-2 my-4 transaction_padding_top tops">
                <div className="headerss">
                  <span className="dash-head">CMS Setting</span>
                  <div>
                    {add == false ? (
                      <input className="filters"
                        placeholder="Filter"
                        value={filterKeyword}
                        onChange={handleFilterChange}
                      />
                    ) : (
                      <button onClick={() => setadd(false)}>Back</button>
                    )}
                  </div>
                </div>
                {add == false ? (

                  <div class="table-responsive my-5  trans-table ">
                    <table className="w_100">
                      <thead className="trans-head">
                        <th>S.No</th>
                        <th>Heading</th>
                        <th>Status</th>
                        <th>Action</th>
                      </thead>

                      <tbody>
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((item, i) => (
                            <tr key={item._id}>
                              <td>
                                <span className="plus_14_ff">{i + 1}</span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  {item.heading}
                                </span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  {item.status == "1" ? "Active" : "Disabled"}
                                </span>
                              </td>
                              <td>
                                <label className="plus_14_ff">
                                  <i class="fa-regular fa-pen-to-square" onClick={() => getEditDetails(item._id)}></i>

                                </label>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className="empty_data">
                                <div className="plus_14_ff">No Records Found</div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* <tr className="text-center">
                          <td colSpan="6">
                            <div className="paginationcss">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"**"}
                                pageCount={2}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={0}
                                onPageChange={""}
                                containerClassName={
                                  "pagination pagination-md justify-content-center "
                                }
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div>
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="row justify-content-center mt-5">
                    <div className="currencyinput  col-lg-9">
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Heading
                        </label>
                        <div class=" col-lg-6">
                          <input
                            class="form-control"
                            type="text"

                            onChange={handleChange}
                            name="heading"
                            value={cmsdataref.current.heading}
                            placeholder="Currency Name"
                            required
                          />
                          {errors.heading && <div className="text-danger">{errors.heading}</div>}

                        </div>
                      </div>
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Title
                        </label>
                        <div class=" col-lg-6">
                          <input
                            type="text"
                            value={cmsdataref.current.title}
                            onChange={handleChange}
                            name="title"
                            placeholder="Currency Symbol"
                            class="form-control"
                            required
                          />
                          {errors.title && <div className="text-danger">{errors.title}</div>}

                        </div>
                      </div>

                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Link
                        </label>
                        <div class=" col-lg-6">
                          <input
                            type="text"
                            value={cmsdataref.current.link}
                            placeholder="Contract Address"
                            onChange={handleChange}
                            name="link"
                            class="form-control"
                          />
                          {errors.link && <div className="text-danger">{errors.link}</div>}

                        </div>
                      </div>
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Meta-Keyword
                        </label>
                        <div class=" col-lg-6">
                          <input
                            type="text"
                            placeholder="Currency Decimal"
                            value={cmsdataref.current.meta_keyword}

                            onChange={handleChange}
                            name="meta_keyword"
                            class="form-control"
                          />
                          {errors.meta_keyword && <div className="text-danger">{errors.meta_keyword}</div>}

                        </div>
                      </div>
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Meta-Description
                        </label>
                        <div class=" col-lg-6">
                          <input
                            type="text"
                            value={cmsdataref.current.meta_description}
                            onChange={handleChange}
                            name="meta_description"
                            placeholder="Contract Address"
                            class="form-control"
                          />
                          {errors.meta_description && <div className="text-danger">{errors.meta_description}</div>}

                        </div>
                      </div>
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Status
                        </label>
                        <div class=" col-lg-6">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={cmsdataref.current.status == 1}
                              onChange={() => handleStatusChange(cmsdataref.current._id, cmsdataref.current.status)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class=" col-lg-6 col-form-label form-control-label">
                          Content-Description

                        </label>
                      </div>

                      <div class="form-group row">
                        <label class=" col-lg-12 col-form-label form-control-label">
                          <CKEditor
                            editor={ClassicEditor}
                            data={cmsdataref.current.content_description}
                            onReady={editor => {
                              console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setEditorData(data);
                            }}
                          />
                          {errors.content_description && <div className="text-danger">{errors.content_description}</div>}

                        </label>
                      </div>
                      <div class="form-group row justify-content-around mt-4">
                        <label class=" col-lg-4 d-flex align-items-center w_100">
                          <button
                            class="btn btn-primary btn-lg float-right w_100"
                            onClick={() => setadd(false)}
                          >
                            Back
                          </button>
                        </label>
                        <div class=" col-lg-4 d-flex align-items-center">
                          <button class="btn btn-lg btn-primary  float-left w_100" onClick={formSubmit}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
