import React, { useState, useEffect } from "react";
import moment from "moment";
import Sidebar from "./Sidebar";
import Sidebar_2 from "./Nav_bar";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";

const ProfileComponent = () => {
  const [model, setModel] = useState({});
  const [adminDetail, setAdminDetail] = useState({});
  const [tfaBtn, setTfaBtn] = useState("");
  const [tfaLoader, setTfaLoader] = useState(false);

  useEffect(() => {
    getProfileData();
  }, []);

  const getProfileData = async () => {
    try {
      const datas = { apiUrl: apiService.getAdminProfile };
      const resData = await getMethod(datas);
      //   const resData = await getMethod(apiService.getAdminProfile);
      if (resData.status) {
        setModel({ ...model, email: resData.data.email });
        setAdminDetail(resData.data);
        setTfaBtn(resData.data.tfa_status === 1 ? "Disable" : "Enable");
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const submit = async (event) => {
    console.log(model, "knweknkewnkecnwek");

    event.preventDefault();
    console.log(model, "knweknkewnkecnwek");
    const { oldpass, newpass, cpass } = model;

    if (oldpass) {
    console.log(model, "knweknkewnkecnwek");

      try {
        // const checkPassData = await postMethod(apiService.checkPassword, {
        //   password: oldpass,
        // });

        var obj ={
          password: oldpass,
        }

        var data = {
          apiUrl: apiService.checkPassword,
          payload: obj,
      };
      var checkPassData = await postMethod(data);


    console.log(checkPassData, "knweknkewnkecnwek");



        if (!checkPassData.status) {
          toast.error(checkPassData.Message, "Error!");
        } else if (newpass === cpass) {
          // const updateProfileData = await postMethod(apiService.updateProfile, {
            
          // });

          var obj ={
            password: newpass,
          }

          var data = {
            apiUrl: apiService.updateProfile,
            payload: obj,
        };

      var updateProfileData = await postMethod(data);


        
          if (updateProfileData.status) {
            toast.success(updateProfileData.Message, "Success!");
            getProfileData();
            localStorage.clear();
            window.location.href = "/admin";
          } else {
            toast.error(updateProfileData.Message, "Error!");
          }
        } else {
          toast.error("Confirm Password is Mismatched", "Error!");
          getProfileData();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const onKey = async (data) => {
    try {
      const resData = await postMethod(apiService.checkPassword, {
        password: data,
      });
      if (!resData.status) {
        toast.error(resData.Message, "Error!");
        getProfileData();
      }
    } catch (error) {
      console.error("Error checking password:", error);
    }
  };

  const tfaStatus = async (event) => {
    event.preventDefault();
    try {

      var obj ={
        tfa_code: model.tfa_code,
      }

      var data = {
        apiUrl: apiService.updateTfa,
        payload: obj,
    };

  var res = await postMethod(data);

      // const res = await postMethod(apiService.updateTfa, {
      //   tfa_code: model.tfa_code,
      // });
      if (res.status) {
        model.tfa_code = "";
        setTfaBtn(res.tfa_status === 1 ? "Disable" : "Enable");
        getProfileData();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
      event.target.reset();
    } catch (error) {
      console.error("Error updating TFA status:", error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="px-2 my-4 transaction_padding_top tops">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="admin_login_main">
                      <div className="hero_subhead">
                        <span className="hero_head text-align-center">
                          Change Password
                        </span>
                        <div className="ycho_inner">
                          <div className="hotpic_bot_hd">
                            <span className="hero_sub_inner">Old Password</span>
                            <input
                              className="admin_login_imput"
                              type="password"
                              value={model.oldpass || ""}
                              onChange={(e) =>
                                setModel({
                                  ...model,
                                  oldpass: e.target.value,
                                })
                              }
                              name="oldpass"
                              placeholder="Enter old password"
                              required
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            />
                          </div>
                          <div className="hotpic_bot_hd">
                            <span className="hero_sub_inner">New Password</span>
                            <input
                              className="admin_login_imput"
                              type="password"
                              value={model.newpass || ""}
                              onChange={(e) =>
                                setModel({
                                  ...model,
                                  newpass: e.target.value,
                                })
                              }
                              name="newpass"
                              placeholder="Enter new password"
                              required
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            />
                          </div>

                          <div className="hotpic_bot_hd">
                            <span className="hero_sub_inner">
                              Confirm Password
                            </span>
                            <input
                              className="admin_login_imput"
                              type="password"
                              value={model.cpass || ""}
                              onChange={(e) =>
                                setModel({
                                  ...model,
                                  cpass: e.target.value,
                                })
                              }
                              name="cpass"
                              placeholder="Enter confirm password"
                              required
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w_100">
                          <div className="lan_had_con">
                            <span className="con_lan_con" onClick={submit}>
                              Submit
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="admin_login_main">
                      <div className="hero_subhead">
                        <span className="hero_head text-align-center">TFA</span>
                        <div className="ycho_inner">
                          {adminDetail.tfa_status == 0 && (
                            <div className="row">
                              <div className="col-md-12">
                                <img
                                  src={adminDetail.tfa_url || ""}
                                  width="150px"
                                  alt="qr code"
                                  className="p-2 bg-white rounded"
                                />
                              </div>
                              <div className="col-md-12">
                                <p className="text_light_grey text-white mt-3 font_12 mb-2 acc-tok-mob">
                                  Scan QR Code or Enter Secret Key Manually
                                  <br />
                                  {}
                                  <div className="hotpic_bot_hd mt-4">
                                    <span className="hero_sub_inner">
                                      {" "}
                                      2FA Secret Key
                                    </span>

                                    <input
                                      className="admin_login_imput"
                                      type="text"
                                      value={adminDetail.tfa_code || ""}
                                    />
                                  </div>
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="hotpic_bot_hd">
                            {adminDetail.tfa_status == 1 ? (
                              <span className="hero_sub_inner">
                                {" "}
                                Enter TFA code to disable
                              </span>
                            ) : (
                              <span className="hero_sub_inner">
                                {" "}
                                Enter TFA code to Enable
                              </span>
                            )}
                            <input
                              className="admin_login_imput"
                              type="text"
                              value={model.tfa_code || ""}
                              onChange={(e) =>
                                setModel({
                                  ...model,
                                  tfa_code: e.target.value,
                                })
                              }
                              name="tfa_code"
                              placeholder="Code"
                              pattern="^\d{6}$"
                              required
                              maxLength="6"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center w_100">
                          <div className="lan_had_con">
                            <span className="con_lan_con" onClick={tfaStatus}>
                              {tfaBtn}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
