import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Currency from "./pages/Currencymanagement";
import Wallet from "./pages/Walletmanagement";
import User from "./pages/Usermanagemnet";
import Deposit from "./pages/Depositmanagement";
import Withdraw from "./pages/Withdrawmanagement";
import CMS from "./pages/CMSmanagement";
import Email from "./pages/Emailmanagement";
import Profit from "./pages/Profitmanagement";
import Profile from "./pages/Profile";
import SiteSettings from "./pages/Sitesettings";
import TFA from "./pages/Tfa";
import StakingHistory from "./pages/StakingHistory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/currencymanagement" element={<Currency />} />
          <Route path="/walletmanagement" element={<Wallet />} />
          <Route path="/usermanagement" element={<User />} />
          <Route path="/depositmanagement" element={<Deposit />} />
          <Route path="/withdrawmanagement" element={<Withdraw />} />
          <Route path="/cmsmanagement" element={<CMS />} />
          <Route path="/emailmanagement" element={<Email />} />
          <Route path="/profitmanagement" element={<Profit />} />
          <Route path="/adminprofile" element={<Profile />} />
          <Route path="/sitesetting" element={<SiteSettings />} />
          <Route path="/verify-2fa" element={<TFA />} />
          <Route path="/stakingHistory" element={<StakingHistory />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
