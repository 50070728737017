import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import Sidebar_2 from "./Nav_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import axios from "axios";
import { env } from "../core/service/envconfig";

function Dashboard() {
  const [add, setAdd] = useState(false);
  const [Currencydata, setCurrencydata] = useState([]);
  const [cmsdata, setcmsdata] = useState([]);

  const [filterKeyword, setFilterKeyword] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    symbol: "",
    currencyType: "",
    erc20token: "",
    trc20token: "",
    bep20token: "",
    erc20Contract: "",
    erc20Decimal: "",
    bep20Contract: "",
    bep20Decimal: "",
    trc20Contract: "",
    trc20Decimal: "",
    minWithdrawLimit: "",
    maxWithdrawLimit: "",
    minDepositLimit: "",
    maxDepositLimit: "",
    withdrawFee: "",
    swapFee: "",
    minSwapAmount: "",
    maxSwapAmount: "",
    status: "",
    depositStatus: "",
    withdrawStatus: "",
    swapStatus: "",
    currencyImage: "",
  });

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    const datas = { apiUrl: apiService.allCurrencyListCrypto };
    const response = await getMethod(datas);
    if (response.status) {
      setCurrencydata(response.data);
    } else {
      setCurrencydata([]);
    }
  };

  const handleFilterChange = (e) => {
    setFilterKeyword(e.target.value);
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\s/g, "");
    let formData = { ...cmsdata, ...{ [name]: sanitizedValue } };
    setcmsdata(formData);
    console.log(cmsdata, "handlechange");
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    // Create a form data object
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", env.upload_preset);
    formData.append("cloud_name", env.cloud_name);
    // fetch(
    //   "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
    //   { m
    try {
      // Upload the image to Cloudinary
      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${env.cloud_name}/image/upload`,
        formData
      ); // Replace 'your_cloud_name' with your Cloudinary cloud name

      // Get the image URL from the response
      const imageUrl = response.data.secure_url;

      // Update the state with the image URL
      setcmsdata({ ...cmsdata, currencyImage: imageUrl });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add logic to handle form submission
    console.log("Form data submitted:", formData);
  };

  const filteredUsers = Currencydata.filter(
    (user) =>
      user &&
      user.symbol &&
      user.symbol.toLowerCase().includes(filterKeyword.toLowerCase())
  );

  const getEditDetails = async (data) => {
    console.log(data, "data");

    var obj = {
      _id: data,
    };
    var datas = {
      apiUrl: apiService.currency_get,
      payload: obj,
    };
    var response = await postMethod(datas);
    console.log(response, "=-=-=-=response=-=-=");

    if (response.status) {
      setcmsdata(response.data);
      setAdd(true);
    } else {
      setcmsdata({});
    }
  };

  const [errors, setErrors] = useState({});

  const validateCurrencyData = () => {
    let formErrors = {};

    // Validate currencyName
    if (!cmsdata.currencyName)
      formErrors.currencyName = "Currency Name is required";

    // Validate currencySymbol
    if (!cmsdata.currencySymbol)
      formErrors.currencySymbol = "Currency Symbol is required";

    // Validate currencyType (assuming it's required and must be a valid type)
    if (!cmsdata.currencyType)
      formErrors.currencyType = "Currency Type is required";

    // Validate token fields
    if (!cmsdata.erc20token) formErrors.erc20token = "ERC20 Token is required";
    if (!cmsdata.trc20token) formErrors.trc20token = "TRC20 Token is required";
    if (!cmsdata.bep20token) formErrors.bep20token = "BEP20 Token is required";

    // Validate contract address fields
    if (cmsdata.currencyType === "2" && !cmsdata.contractAddress_erc20) {
      formErrors.contractAddress_erc20 =
        "ERC20 Contract Address is required for this currency type";
    }
    if (cmsdata.bep20token === "1" && !cmsdata.contractAddress_bep20) {
      formErrors.contractAddress_bep20 = "BEP20 Contract Address is required";
    }
    if (!cmsdata.coinDecimal_bep20)
      formErrors.coinDecimal_bep20 = "BEP20 Coin Decimal is required";

    // Validate numeric limits
    if (cmsdata.minWithdrawLimit <= 0)
      formErrors.minWithdrawLimit =
        "Minimum Withdraw Limit must be greater than 0";
    if (cmsdata.maxWithdrawLimit <= cmsdata.minWithdrawLimit)
      formErrors.maxWithdrawLimit =
        "Maximum Withdraw Limit must be greater than Minimum Withdraw Limit";
    if (cmsdata.minDepositLimit <= 0)
      formErrors.minDepositLimit =
        "Minimum Deposit Limit must be greater than 0";
    if (cmsdata.maxDepositLimit <= cmsdata.minDepositLimit)
      formErrors.maxDepositLimit =
        "Maximum Deposit Limit must be greater than Minimum Deposit Limit";
    if (cmsdata.withdrawFee < 0)
      formErrors.withdrawFee = "Withdraw Fee cannot be negative";
    if (cmsdata.swapFee < 0) formErrors.swapFee = "Swap Fee cannot be negative";
    if (cmsdata.minSwap <= 0)
      formErrors.minSwap = "Minimum Swap must be greater than 0";
    if (cmsdata.maxSwap <= cmsdata.minSwap)
      formErrors.maxSwap = "Maximum Swap must be greater than Minimum Swap";

    // Validate status fields
    const validStatuses = ["Active", "Inactive"];
    if (!validStatuses.includes(cmsdata.status))
      formErrors.status = "Invalid Status";
    if (!validStatuses.includes(cmsdata.depositStatus))
      formErrors.depositStatus = "Invalid Deposit Status";
    if (!validStatuses.includes(cmsdata.withdrawStatus))
      formErrors.withdrawStatus = "Invalid Withdraw Status";
    if (!["1", "0"].includes(cmsdata.swapStatus))
      formErrors.swapStatus = "Invalid Swap Status";

    // Validate currencyImage URL (basic check)
    if (
      !cmsdata.currencyImage ||
      !/^https?:\/\/.*\.(jpg|jpeg|png|gif)$/.test(cmsdata.currencyImage)
    ) {
      formErrors.currencyImage = "Invalid Currency Image URL";
    }

    // Update the state with the form errors
    setErrors(formErrors);

    // Return true if no errors, false otherwise
    return Object.keys(formErrors).length === 0;
  };

  const formSubmit = async () => {
    // if (!validateCurrencyData()) {
    //   console.log("Required fields are missing");
    //   return;
    // }

    validateCurrencyData();

    var data = {
      apiUrl: apiService.currencyAddUpdate,
      payload: cmsdata,
    };
    var resp = await postMethod(data);

    if (resp.status) {
      toast.success(resp.Message);
      setAdd(false);
      getUserDetails();
    } else {
      toast.error(resp.Message);
      setAdd(false);
      getUserDetails();
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 px-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 px-0">
            <div className="pos_sticky">
              <Sidebar_2 />
            </div>
            <div className="px-4 transaction_padding_top">
              <div className="px-2 my-4 transaction_padding_top tops">
                <div className="headerss">
                  <span className="dash-head">Crypto Currency Settings</span>
                  {add === false ? (
                    <div>
                      <input
                        className="filters"
                        placeholder="Filter"
                        value={filterKeyword}
                        onChange={handleFilterChange}
                      />
                      {/* <i
                        className="fa-solid fa-circle-plus adds"
                        onClick={() => setAdd(true)}
                      ></i> */}
                    </div>
                  ) : (
                    <button onClick={() => setAdd(false)}>Back</button>
                  )}
                </div>
                {add === false ? (
                  <div className="table-responsive my-5 trans-table">
                    <table className="w_100">
                      <thead className="trans-head">
                        <tr>
                          <th>S.No</th>
                          <th>Currency Image</th>
                          <th>Symbol</th>
                          <th>Status</th>
                          <th>Action</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((item, i) => (
                            <tr key={item._id}>
                              <td>
                                <span className="plus_14_ff">{i + 1}</span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  <img
                                    width="35px"
                                    src={item.Currency_image}
                                    alt="Currency"
                                  />
                                </span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  {item.symbol}
                                </span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  {item.status}
                                </span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  <i
                                    className="fa-regular fa-pen-to-square"
                                    onClick={() => getEditDetails(item._id)}
                                  ></i>
                                </span>
                              </td>
                              <td>
                                <span className="plus_14_ff">
                                  <i className="fa-regular fa-trash-can"></i>
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <div className="empty_data">
                                <div className="plus_14_ff">
                                  No Records Found
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <form
                    onSubmit={handleSubmit}
                    className="row justify-content-center mt-5"
                  >
                    <div className="currencyinput col-lg-9">
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Currency Name
                        </label>
                        <div className="col-lg-6">
                          <input
                            className="form-control"
                            type="text"
                            name="currencyName"
                            value={cmsdata.currencyName}
                            onChange={handleChange}
                            placeholder="Currency Name"
                            required
                          />
                          <div className="help-block">
                            {errors.currencyName && (
                              <div className="error">{errors.currencyName}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Currency Symbol
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            name="currencySymbol"
                            value={cmsdata.currencySymbol}
                            onChange={handleChange}
                            placeholder="Currency Symbol"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.currencySymbol && (
                              <div className="error">
                                {errors.currencySymbol}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Asset Type
                        </label>
                        <div className="col-lg-6">
                          <div className="radio">
                            <div>
                              <input
                                type="radio"
                                name="currencyType"
                                value="1"
                                onChange={handleChange}
                                checked={cmsdata.currencyType === "1"}
                                required
                              />{" "}
                              Coin
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="currencyType"
                                value="2"
                                onChange={handleChange}
                                checked={cmsdata.currencyType === "2"}
                                required
                              />{" "}
                              Token
                            </div>
                          </div>
                          <div className="help-block">
                            {errors.currencyType && (
                              <div className="error">{errors.currencyType}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          ERC20 Token
                        </label>
                        <div className="col-lg-6">
                          <div className="radio">
                            <div>
                              <input
                                type="radio"
                                name="erc20token"
                                value="1"
                                onChange={handleChange}
                                checked={cmsdata.erc20token === "1"}
                                required
                              />{" "}
                              True
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="erc20token"
                                value="2"
                                onChange={handleChange}
                                checked={cmsdata.erc20token === "2"}
                                required
                              />{" "}
                              False
                            </div>
                          </div>
                          <div className="help-block">
                            {errors.erc20token && (
                              <div className="error">{errors.erc20token}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          TRC20 Token
                        </label>
                        <div className="col-lg-6">
                          <div className="radio">
                            <div>
                              <input
                                type="radio"
                                name="trc20token"
                                value="1"
                                onChange={handleChange}
                                checked={cmsdata.trc20token === "1"}
                                required
                              />{" "}
                              True
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="trc20token"
                                value="2"
                                onChange={handleChange}
                                checked={cmsdata.trc20token === "2"}
                                required
                              />{" "}
                              False
                            </div>
                          </div>
                          <div className="help-block">
                            {errors.trc20token && (
                              <div className="error">{errors.trc20token}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          BEP20 Token
                        </label>
                        <div className="col-lg-6">
                          <div className="radio">
                            <div>
                              <input
                                type="radio"
                                name="bep20token"
                                value="1"
                                onChange={handleChange}
                                checked={cmsdata.bep20token === "1"}
                                required
                              />{" "}
                              True
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="bep20token"
                                value="2"
                                onChange={handleChange}
                                checked={cmsdata.bep20token === "2"}
                                required
                              />{" "}
                              False
                            </div>
                          </div>
                          <div className="help-block">
                            {errors.bep20token && (
                              <div className="error">{errors.bep20token}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      {cmsdata.erc20token == "1" ? (
                        <>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              ERC20 Contract
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                name="contractAddress_erc20"
                                value={cmsdata.contractAddress_erc20}
                                onChange={handleChange}
                                placeholder="ERC20 Contract"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.contractAddress_erc20 && (
                                  <div className="error">
                                    {errors.contractAddress_erc20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              ERC20 Decimal
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="number"
                                name="coinDecimal_erc20"
                                value={cmsdata.coinDecimal_erc20}
                                onChange={handleChange}
                                placeholder="ERC20 Decimal"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.coinDecimal_erc20 && (
                                  <div className="error">
                                    {errors.coinDecimal_erc20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {cmsdata.bep20token == "1" ? (
                        <>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              BEP20 Contract
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                name="contractAddress_bep20"
                                value={cmsdata.contractAddress_bep20}
                                onChange={handleChange}
                                placeholder="BEP20 Contract"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.contractAddress_bep20 && (
                                  <div className="error">
                                    {errors.contractAddress_bep20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              BEP20 Decimal
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="number"
                                name="coinDecimal_bep20"
                                value={cmsdata.coinDecimal_bep20}
                                onChange={handleChange}
                                placeholder="BEP20 Decimal"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.coinDecimal_bep20 && (
                                  <div className="error">
                                    {errors.coinDecimal_bep20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {cmsdata.trc20token == "1" ? (
                        <>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              TRC20 Contract
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="text"
                                name="contractAddress_trc20"
                                value={cmsdata.contractAddress_trc20}
                                onChange={handleChange}
                                placeholder="TRC20 Contract"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.contractAddress_trc20 && (
                                  <div className="error">
                                    {errors.contractAddress_trc20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-6 col-form-label form-control-label">
                              TRC20 Decimal
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="number"
                                name="coinDecimal_trc20"
                                value={cmsdata.coinDecimal_trc20}
                                onChange={handleChange}
                                placeholder="TRC20 Decimal"
                                className="form-control"
                                required
                              />
                              <div className="help-block">
                                {errors.coinDecimal_trc20 && (
                                  <div className="error">
                                    {errors.coinDecimal_trc20}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Min Withdraw Limit
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="minWithdrawLimit"
                            value={cmsdata.minWithdrawLimit}
                            onChange={handleChange}
                            placeholder="Min Withdraw Limit"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.minWithdrawLimit && (
                              <div className="error">
                                {errors.minWithdrawLimit}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Max Withdraw Limit
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="maxWithdrawLimit"
                            value={cmsdata.maxWithdrawLimit}
                            onChange={handleChange}
                            placeholder="Max Withdraw Limit"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.maxWithdrawLimit && (
                              <div className="error">
                                {errors.maxWithdrawLimit}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Min Deposit Limit
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="minDepositLimit"
                            value={cmsdata.minDepositLimit}
                            onChange={handleChange}
                            placeholder="Min Deposit Limit"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.minDepositLimit && (
                              <div className="error">
                                {errors.minDepositLimit}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Max Deposit Limit
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="maxDepositLimit"
                            value={cmsdata.maxDepositLimit}
                            onChange={handleChange}
                            placeholder="Max Deposit Limit"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.maxDepositLimit && (
                              <div className="error">
                                {errors.maxDepositLimit}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Withdraw Fee
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="withdrawFee"
                            value={cmsdata.withdrawFee}
                            onChange={handleChange}
                            placeholder="Withdraw Fee"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.withdrawFee && (
                              <div className="error">{errors.withdrawFee}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Swap Fee
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="swapFee"
                            value={cmsdata.swapFee}
                            onChange={handleChange}
                            placeholder="Swap Fee"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.swapFee && (
                              <div className="error">{errors.swapFee}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Min Swap
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="minSwap"
                            value={cmsdata.minSwap}
                            onChange={handleChange}
                            placeholder="Min Swap"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.minSwap && (
                              <div className="error">{errors.minSwap}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Max Swap
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="number"
                            name="maxSwap"
                            value={cmsdata.maxSwap}
                            onChange={handleChange}
                            placeholder="Max Swap"
                            className="form-control"
                            required
                          />
                          <div className="help-block">
                            {errors.maxSwap && (
                              <div className="error">{errors.maxSwap}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Status
                        </label>
                        <div className="col-lg-6">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="status"
                              value="Active"
                              checked={cmsdata.status === "Active"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="status"
                              value="Inactive"
                              checked={cmsdata.status === "Inactive"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Inactive</label>
                          </div>
                          <div className="help-block">
                            {errors.status && (
                              <div className="error">{errors.status}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Deposit Status
                        </label>
                        <div className="col-lg-6">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="depositStatus"
                              value="Active"
                              checked={cmsdata.depositStatus === "Active"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="depositStatus"
                              value="Inactive"
                              checked={cmsdata.depositStatus === "Inactive"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Inactive</label>
                          </div>
                          <div className="help-block">
                            {errors.depositStatus && (
                              <div className="error">
                                {errors.depositStatus}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Withdraw Status
                        </label>
                        <div className="col-lg-6">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="withdrawStatus"
                              value="Active"
                              checked={cmsdata.withdrawStatus === "Active"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Active</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="withdrawStatus"
                              value="Inactive"
                              checked={cmsdata.withdrawStatus === "Inactive"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Inactive</label>
                          </div>
                          <div className="help-block">
                            {errors.withdrawStatus && (
                              <div className="error">
                                {errors.withdrawStatus}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Swap Status
                        </label>
                        <div className="col-lg-6">
                          <div className="form-check">
                            <input
                              type="radio"
                              name="swapStatus"
                              value="1"
                              checked={cmsdata.swapStatus == "1"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Enabled</label>
                          </div>
                          <div className="form-check">
                            <input
                              type="radio"
                              name="swapStatus"
                              value="0"
                              checked={cmsdata.swapStatus == "0"}
                              onChange={handleChange}
                              className="form-check-input"
                              required
                            />
                            <label className="form-check-label">Disabled</label>
                          </div>
                          <div className="help-block">
                            {errors.swapStatus && (
                              <div className="error">{errors.swapStatus}</div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-lg-6 col-form-label form-control-label">
                          Currency Image
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="file"
                            name="currencyImage"
                            onChange={handleFileChange}
                            className="form-control"
                            required
                          />
                          <img
                            src={cmsdata.Currency_image}
                            alt="Currency"
                            style={{ width: "100px", marginTop: "10px" }}
                          />
                          <div className="help-block">
                            {errors.Currency_image && (
                              <div className="error">
                                {errors.Currency_image}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-12 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={formSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
